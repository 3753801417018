<template>
  <div style="min-width: 1900px; overflow: hidden">
    <div
      class="head"
      v-if="recommendList[headIndex]"
      :style="
        'background-image: url(' + recommendList[headIndex].newsCover + ');'
      "
    >
      <div class="head_minBg"></div>
      <div class="headTv">
        <img class="hotImg" src="../../../static/v1.0/hotnew.png" alt="" />
        <div class="headBox">
          <div class="head_img">
            <!-- loop="false" -->
            <el-carousel
              arrow="never"
              :interval="5000"
              ref="headCarousel"
              @change="carouselC"
            >
              <el-carousel-item
                v-for="(item, index) in recommendList"
                :key="index"
              >
                <div
                  class="head_bg"
                  style="cursor: pointer"
                  @click="toDetails(item, index)"
                >
                  <div class="mask"></div>
                  <img :src="item.newsCover" width="100%" />
                </div>
                <div class="head_item">
                  <div class="head_font">{{ item.newsTitle }}</div>
                  <div style="display: flex; opacity: 0.6; padding-top: 20px">
                    <div class="head_font1" style="margin-right: 30px">
                      {{ item.publishTime }}
                    </div>
                    <div class="head_font1">
                      发布单位：{{
                        item.publishUnit && item.publishUnit.companyName
                          ? item.publishUnit.companyName
                          : "中峪农业"
                      }}
                    </div>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="cornerMarkBox">
            <div
              :class="{ ckcss: cssCk == index }"
              class="cornerMark"
              v-for="(item, index) in recommendList"
              :key="index"
              @click="cornerCM(index)"
            >
              {{ index + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="middle moveTopNormal" v-if="middle_journalism.length">
      <div class="imgbox"></div>
      <div class="titile" style="height: 420px">
        <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
        <img class="twoI" src="../../../static/v1.0/two.png" alt="" />
        <img class="img" src="../../../static/v1.0/新·闻·中·心.png" alt="" />
        <span class="imgT">NEWS CENTER</span>
      </div>
      <div style="max-width: 1900px; margin: 0 auto">
        <div class="middle_new">
          <div class="middle_Left">
            <el-carousel
              :interval="5000"
              ref="middleCarousel"
              arrow="never"
              @change="carouselMid"
              @click="toDetails(item, index)"
            >
              <el-carousel-item
                :style="`background-image: url(${item.newsCover});background-size: cover;`"
                v-for="(item, index) in middle_journalism"
                :key="index"
              >
                <div
                  style="min-width: 896px; height: 512.4px"
                  @click="toDetails(item, index)"
                ></div>
                <div class="mask" @click="toDetails(item, index)"></div>
                <div class="middle_item" @click="toDetails(item, index)">
                  <div class="middle_font">{{ item.newsTitle }}</div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="cornerMarkBoxMid">
              <div
                :class="{ ckcss: midCk == item }"
                class="cornerMarkMid"
                v-for="(item, index) in middle_journalism"
                :key="index"
                @click="cornerMid(index + 1)"
              >
                <div style="color: #fff">{{ index + 1 }}</div>
              </div>
            </div>
          </div>
          <div class="middle_Rigth">
            <div
              v-for="(item, index) in middle_journalism"
              @click="toDetails(item, index)"
              :key="index"
            >
              <div
                class="middle_Ritem moveTop1"
                @mouseover="mouseOverNews(index)"
              >
                <div class="middle_Rfont">{{ item.newsTitle }}</div>
                <div
                  style="display: flex; line-height: 16px; margin-bottom: 46px"
                >
                  <div class="minddle_time">
                    {{ item.publishTime }}
                  </div>
                  <div style="margin: 0 20px 0 29px">|</div>
                  <div class="minddle_danwie">
                    {{ item.newsIntroduction || item.newsTitle }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="middle_three">
          <el-carousel
            indicator-position="none"
            :autoplay="false"
            arrow="never"
            height="600px"
            ref="newsTypeCarousel"
          >
            <el-carousel-item v-for="num in 3" :key="num">
              <div class="swiper-container swiper-container3">
                <div class="swiper-wrapper">
                  <div
                    class="swiper-slide home_module_8_module"
                    v-for="(arr, index) in middle_journalism_three"
                    :key="index"
                  >
                    <div
                      class="home_module_8_module_item"
                      v-for="(item, index2) in arr"
                      :key="index2"
                      @mouseout="tomouseout"
                      @mousemove="tomousemove(index2)"
                      @click="toDetails(item)"
                    >
                      <div class="home_module_8_cover">
                        <img :src="item.newsCover" />
                      </div>
                      <div class="home_module_8_date">
                        <div>{{ item.publishTime }}</div>
                      </div>
                      <div class="home_module_8_title">
                        <div>{{ item.newsTitle }}</div>
                      </div>
                      <div class="home_module_8_more1">
                        <img
                          v-show="mousemoveIndex != index2"
                          src="../../../static/v1.0/L_b.png"
                          alt=""
                          srcset=""
                        />
                        <div v-show="mousemoveIndex == index2">
                          <img :src="introduction_bg1" class="one" alt="" />
                          <img
                            :src="introduction_bg1"
                            class="two"
                            style="margin: 0 3px"
                            alt=""
                          />
                          <img :src="introduction_bg1" class="three" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="home_module_8_content_right">
                <div class="home_module_8_content_dom">
                  <div v-if="listIndex >= 6">
                    <i
                      class="el-icon-arrow-left icon-left"
                      :class="newsTypeIndex == 0 ? 'nodata' : ' moveTop'"
                      @click="changeNewsTypeIndex(-1)"
                    ></i>
                    <i
                      class="el-icon-arrow-right icon-right"
                      :class="newsTypeIndex == 2 ? 'nodata' : ' moveTop'"
                      @click="changeNewsTypeIndex(1)"
                    ></i>
                  </div>
                  <div v-else></div>
                  <!-- class="home_module_8_content_right_more moveTop" -->

                  <div
                    style="cursor: pointer"
                    @click="toPage('/newsList', { id: num })"
                  >
                    查看更多>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="middle1" v-if="!middle_journalism.length">
      {{ companyItem.companyName }}
    </div>
    <div class="specific">
      <div class="specific_dom">
        <div
          class="font_left"
          :style="
            middle_journalism.length ? 'padding-top: 75px' : 'padding-top: 0'
          "
        >
          <div class="title" v-if="middle_journalism.length">
            {{ companyItem.companyName }}
          </div>
          <div
            class="font_"
            :style="middle_journalism.length ? '' : 'margin-top: 0'"
          >
            {{ companyItem.companyDesc }}
          </div>
          <div class="font_top">
            <div style="cursor: pointer">
              <div class="number" @click="onbaseAll(0, { id: 7 })">
                {{ statisticInfo.plantedNumber }}
              </div>
              <div class="numberTpye" @click="onbaseAll(0, { id: 7 })">
                种植面积(亩)
              </div>
            </div>
            <div>
              <div class="number">{{ statisticInfo.projectPersonnel }}</div>
              <div class="numberTpye">工作人员(人)</div>
            </div>
            <div>
              <div class="number">{{ statisticInfo.subordinateUnits }}</div>
              <div class="numberTpye">下属单位(家)</div>
            </div>
            <div style="cursor: pointer">
              <div class="number" @click="onbaseAll(0, { id: 7 })">
                {{ statisticInfo.projectBaseNumber }}
              </div>
              <div class="numberTpye" @click="onbaseAll(0, { id: 7 })">
                基地数量(个)
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative">
        <div class="backimg"></div>
        <div style="width: 1900px; margin: 0 auto">
          <div class="specific_field">
            <div class="field_dom">
              <div
                :style="`background: url(${item.companyImage})`"
                class="item_fieDom"
                @click="jumpcityCompany(item)"
                v-for="(item, index) in medicinalCompanyList"
                :key="index"
              >
                <div class="mask"></div>
                <div class="item_Dom" style="">
                  <div class="item_title">{{ item.companyName }}</div>
                  <div class="item_cent">
                    {{ item.companyDesc }}
                  </div>
                  <!-- <div class="jiaobioa">{{ index }}</div> -->
                  <div style="text-align: center; margin-top: 20px">
                    <img src="../../../static/v1.0/L.png" alt="" srcset="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="base moveTopNormal">
      <div class="base_bul">
        <div style="max-width: 1900px; margin: 0 auto">
          <div class="titile">
            <img class="oneI" src="../../../static/v1.0/one.png" alt="" />
            <img
              class="img"
              src="../../../static/v1.0/基·地·中·心.png"
              alt=""
            />
            <span class="imgT">Base center</span>
          </div>
          <div class="base_dom">
            <div
              :class="{ onbaseClass: item.id == onbase }"
              class="base_all_dom"
              v-for="(item, index) in baseList"
              :key="index"
            >
              <div class="base_all" @click="changeProject(item, index)">
                <img
                  v-if="item.id == onbase"
                  src="../../../static/v1.0/yun.png"
                  class="base_img"
                  alt=""
                />
                <div class="base_font">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="base_child">
            <div
              class="base_child_info_project"
              v-if="childProjectList.length > 0"
            >
              <div
                class="base_child_info_project_item moveTopNormal"
                v-for="item in childProjectList"
                :key="item.projectId"
                @click="jumpClick(item)"
              >
                <div class="base_child_info_project_item_img">
                  <img :src="item.projectImage" width="100%" />
                  <div class="base_child_title" style="">
                    <div
                      style="
                        bottom: 31px;
                        left: 16px;
                        font-family: 楷体;
                        position: absolute;
                      "
                    >
                      {{ item.projectName }}
                    </div>
                  </div>
                </div>
                <div
                  class="base_child_info_project_item_tips"
                  v-if="item.projectId != -1"
                >
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="font-weight: bold"
                  ></div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="
                      font-size: 20px;
                      line-height: 30px;
                      margin-top: 12px;
                      margin-bottom: 12px;
                    "
                  >
                    项目时间：{{ item.projectDate.split(",")[0] }} 至
                    {{ item.projectDate.split(",")[1] }}
                  </div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="
                      font-size: 20px;
                      line-height: 30px;
                      margin-top: 12px;
                      margin-bottom: 12px;
                    "
                  >
                    所属单位：{{ item.projectUnit }}
                  </div>
                  <div
                    class="base_child_info_project_item_tips_left"
                    style="font-size: 20px; line-height: 30px"
                  >
                    <div class="base_child_info_project_item_tips_right">
                      {{ item.projectDesc }}
                    </div>
                  </div>
                  <div class="base_child_info_project_item_tips_more">
                    了解详情<img
                      src="../../../static/v1.0/21.png"
                      width="16px"
                      height="16px"
                    />
                  </div>
                  <div class="base_child_info_project_item_tips_line"></div>
                </div>
              </div>
            </div>
            <div
              class="base_child_info_project"
              v-if="childProjectList.length == 0"
              style="text-align: center; display: block"
            >
              <img
                src="https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-19/dce89a55ac694fecba87dbe7f715a5af.png"
                width="700px"
                style="margin-top: 20px"
              />
              <div
                style="
                  width: 100%;
                  font-weight: bold;
                  font-size: 26px;
                  color: #c9c9c9;
                  line-height: 39px;
                "
              >
                更多基地正在开发中···
              </div>
            </div>

            <div
              class="more more2 moveTop1"
              v-if="childProjectList.length !== 0"
              style="
                position: relative;
                left: 0;
                width: 100%;
                transform: translate(0, 0);
                margin: 0 auto;
                padding-bottom: 100px;
              "
              @click="more2Product"
            >
              查看更多
              <img
                class="more_img"
                src="../../../static/v1.0/L_b.png"
                style="
                  width: 26px;
                  height: 15px;
                  margin-left: 10px;
                  padding-top: 10px;
                  vertical-align: top;
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="product moveTopNormal" v-if="companyItem.companyCategory != 1">
      <div class="product_tit">
        <div style="width: 1568px; margin: 0 auto">
          <div class="titile">
            <img
              class="oneI"
              style="top: 100px"
              src="../../../static/v1.0/one.png"
              alt=""
            />
            <img
              class="img"
              style="top: 116px"
              src="../../../static/v1.0/产·品·中·心.png"
              alt=""
            />
            <span class="imgT">PRODUCT CENTER</span>
          </div>
          <div class="product_font">
            中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。
          </div>
          <div class="product_list">
            <div
              class="product_dom"
              :style="`background: url(${item.img});background-size: cover;`"
              v-for="(item, index) in medicinalList"
              @click="jumpR(item)"
              :key="index"
            >
              <div class="mask"></div>
              <div class="product_dom_font">
                <div class="product_dom_title">{{ item.title }}</div>
                <div class="product_dom_center">{{ item.center }}</div>
                <div style="margin-top: 20px">
                  <img src="../../../static/v1.0/L.png" alt="" srcset="" />
                </div>
              </div>
            </div>
          </div>
          <div class="more moveTop1" style="display: flex" @click="moreProduct">
            查看更多
            <img
              class="more_img"
              src="../../../static/v1.0/L_b.png"
              style="
                width: 26px;
                height: 15px;
                margin-left: 10px;
                padding-top: 11px;
              "
              alt=""
              srcset=""
            />
          </div>
        </div>
      </div>
      <div
        style="
          height: 85px;
          padding-bottom: 105px;
          width: 100%;
          background: #f7f7f7;
        "
      ></div>
    </div>
    <div class="font moveTopNormal">
      <div class="font_dom">战略合作伙伴：</div>
      <div style="display: flex">
        <div class="font_div">
          <div
            class="font_for"
            v-for="(item, index) in companyName"
            :key="index"
          >
            <div style="line-height: 122px">
              <img :src="item.url" width="30px" />
            </div>
            <div style="margin: 0 60px 0 10px">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, Navigation, Pagination, Autoplay, Mousewheel } from "swiper";
import "swiper/swiper-bundle.css";
import dotMap from "../map.vue";
import {
  getCompanyList,
  getNewsList,
  getProject,
  getStatistic,
  getSignature,
} from "@/api/index.js";
Swiper.use([Navigation, Pagination, Autoplay, Mousewheel]);
export default {
  name: "home",
  components: { dotMap },
  data() {
    return {
      headIndex: 0,
      companyName: [
        {
          name: "国家电力投资集团",
          url: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/331a9ccf00f142e0b92d9afcee41f190.png",
        },
        {
          name: "运达能源科技集团股份有限公司",
          url: "https://shituoshili-dev-001.oss-cn-hangzhou.aliyuncs.com/test/2024-09-18/cc6ab34dac7240f1800f026e30468113.png",
        },
      ],
      mousemoveIndex: null,
      mousemoveType: null,
      //热点新闻
      head_journalism: [
        {
          time: "2024-04-05",
          data: "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
        {
          time: "2024-04-15",
          data: "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
        {
          time: "2024-04-25",
          data: "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
        {
          time: "2024-04-35",
          data: "兴农之约再上台阶——中峪农业共富项目在新昌小将镇发展大会上隆重签约，将深化产业合作，推动区域经济高质量发展",
          danwei: "浙江中峪道地药材有限公司",
        },
      ],
      //角标选中
      cssCk: 0,
      midCk: 1,
      //新闻中心
      middle_journalism: [],
      middle_journalism_three: [],
      newsTypeIndex: 0,
      container3: null,
      newsType: 0,
      imgIndex: 0,
      imgIndexF: 0,
      bannerIndex: 0,
      bannerTxt: "手写的处方",
      banner: [
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
        {
          picture_txt:
            "浙江中峪农业发展有限责任公司，自成立以来，一直以守护道地药材为初心，传承发扬中华传统中医药产业为己任。我们专注于道地药材的种子种苗孵化培育、科学精细化种植、研发、加工与销售，始终坚持在原产地种植，确保药材纯正品质，将在全国范围内建立多个…",
          picture_path: "浙江中峪道地药材有限公司",
        },
      ],
      indexC: null,
      indexC2: null,
      baseList: [
        {
          name: "全部基地",
          id: -1,
        },
        {
          name: "种植基地",
          id: 1,
        },
        {
          name: "种子基地",
          id: 0,
        },
        {
          name: "种苗基地",
          id: 2,
        },
        {
          name: "科研基地",
          id: 3,
        },
        {
          name: "能源基地",
          id: 4,
        },
        {
          name: "查看更多",
          id: 7,
        },
      ],
      childProjectList: [],
      medicinalList: [
        {
          title: "关药-远志",
          id: 389,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/远志.jpg"),
        },
        {
          title: "北药-龙骨",
          id: 390,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/龙骨.jpg"),
        },
        {
          title: "怀药-法半夏",
          id: 415,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/法半夏.jpg"),
        },
        {
          title: "维药-甘草",
          id: 396,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/甘草.jpg"),
        },
        {
          title: "浙药-厚朴",
          id: 409,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/厚朴.jpg"),
        },
        {
          title: "海药-牡蛎",
          id: 517,
          center:
            "中医中药在中国古老的大地上已经运用了几千年的历史，经过几千年的临床实践，证实了中国的中医中药无论是在治病上、在防病上，还是在养生上，都是确凿有效可行的。在西医未传入中国之前，我们的祖祖辈辈都用中医中药来治疗疾病，挽救了无数人的生命。",
          img: require("../../../static/image/牡蛎.jpg"),
        },
      ],
      medicinalCompanyList: [],
      introduction_bg: require("../../../static/v1.0/3.png"),
      introduction_bg1: require("../../../static/v1.0/lv_3.png"),
      onbase: -1,
      companyItem: {},
      recommendList: [],
      statisticInfo: {},
      listIndex: 0,
    };
  },

  mounted() {
    this.companyItem = this.$store.getters.getCompany;
    window.scrollTo({
      top: 0,
      left: 0,
    });
    this.getList();
    this.getCompanyList();
    this.getChildProjectList();
    this.getMedicinalCompanyList();
    this.getStatistic();
    this.getWXInfo();
  },

  methods: {
    getStatistic() {
      getStatistic(this.companyItem.companyId).then((res) => {
        const data = res.data;
        this.statisticInfo = {
          plantedNumber:
            data[0].plantedNumber +
            data[1].plantedNumber +
            data[2].plantedNumber,
          projectBaseNumber:
            data[0].projectBaseNumber +
            data[1].projectBaseNumber +
            data[2].projectBaseNumber,
          projectPersonnel:
            data[0].projectPersonnel +
            data[1].projectPersonnel +
            data[2].projectPersonnel,
          subordinateUnits:
            data[0].subordinateUnits +
            data[1].subordinateUnits +
            data[2].subordinateUnits,
        };
      });
    },
    getMedicinalCompanyList() {
      getCompanyList({
        pid: this.companyItem.companyId,
        current: 1,
        size: 2,
      }).then((res) => {
        this.medicinalCompanyList = res.data.list;
      });
    },
    getChildProjectList() {
      getProject({
        companyId: this.companyItem.companyId,
        withChildCompany: 1,
        projectType: this.onbase == -1 ? null : this.onbase,
        current: 1,
        size: 4,
      }).then((res) => {
        if (res.data.list.length == 0) {
          this.childProjectList = [];
        } else {
          this.childProjectList = res.data.list;
        }
        this.$nextTick(() => {
          const arr = document.querySelectorAll(
            ".base_child_info_project_item"
          );
          arr.forEach((val) => {
            val.className = "base_child_info_project_item moveTopNormal";
            setTimeout(() => {
              val.className =
                "base_child_info_project_item moveTopNormal moveTopAnimation";
            }, 500);
          });
        });
      });
    },
    toPage(path, item) {
      this.$router.push(path, item);
    },
    mouseOverNews(index) {
      this.midCk = index + 1;
      this.$refs.middleCarousel.setActiveItem(index);
    },
    getCompanyList() {
      getCompanyList({ current: 1, size: 4, pid: 3 }).then((res) => {
        this.treeData = res.data.list;
        this.treeData.push(res.data.list[0]);
        this.treeData.push(res.data.list[1]);
      });
    },
    changeProject(item, index) {
      if (index != 6) {
        this.onbase = item.id;
        this.getChildProjectList();
      } else {
        this.$router.push({
          path: `/medicinal`,
        });
      }
    },
    onbaseAll(index, item) {
      if (item.id == 6) {
        this.$router.push({
          path: `/medicinal`,
        });
      }
      if (item.id == 2) {
        this.$router.push({
          path: `/introduction`,
        });
      }
      if (item.id == 7) {
        this.$router.push({
          path: `/medicinal`,
        });
      }
    },
    tomouseout() {
      this.mousemoveIndex = null;
    },
    tomousemove(index) {
      this.mousemoveType = true;
      this.mousemoveIndex = index;
    },
    jumpR(item) {
      this.$router.push({
        path: `/medicineDetails?id=${item.id}&tabIndex`,
      });
    },
    getList() {
      getNewsList({
        current: 1,
        size: 4,
        companyId: this.companyItem.companyId,
        recommend: 1,
      }).then((res) => {
        this.recommendList = res.data.list;
      });
      getNewsList({
        current: 1,
        size: 12,
        companyId: this.companyItem.companyId,
        self: 1,
      }).then((res) => {
        this.newsList = res.data.list;
        this.middle_journalism = res.data.list;
        this.middle_journalism = res.data.list.slice(0, 3);
        this.listIndex = res.data.total - 3;
        if (this.listIndex > 9) {
          this.middle_journalism_three = [
            [this.newsList[3], this.newsList[4], this.newsList[5]],
            [this.newsList[6], this.newsList[7], this.newsList[8]],
            [this.newsList[9], this.newsList[10], this.newsList[11]],
          ];
        } else if (this.listIndex > 9 && this.listIndex >= 6) {
          this.middle_journalism_three = [
            [this.newsList[3], this.newsList[4], this.newsList[5]],
            [this.newsList[6], this.newsList[7], this.newsList[8]],
          ];
        } else if (this.listIndex >= 3) {
          this.middle_journalism_three = [
            [this.newsList[3], this.newsList[4], this.newsList[5]],
            // [this.newsList[6], this.newsList[7], this.newsList[8]],
          ];
        }
      });
      this.$nextTick(() => {
        setTimeout(() => {
          this.container3 = new Swiper(".swiper-container3", {
            slidesPerView: 1,
            observer: true, //开启动态检查器，监测swiper和slide
            observeParents: true, //监测Swiper 的祖/父元素
            loop: false,
          });
        }, 500);
        // let that = this;
      });
    },
    toDetails(item, index) {
      this.$router.push({
        path: `/newsListDetail?id=${item.newsId}&type=${item.websiteNames}&index=0`,
      });
    },
    jumpClick(item) {
      if (item.projectId != -1) {
        this.$router.push({
          path: `/projectDetail?id=` + item.projectId,
        });
      }
    },
    cornerCM(item) {
      this.cssCk = item;
      this.headIndex = item;
      this.$refs.headCarousel.setActiveItem(item);
    },
    cornerMid(item) {
      this.midCk = item;
      this.$refs.middleCarousel.setActiveItem(item - 1);
    },
    carouselC(e) {
      this.headIndex = e;
      this.cssCk = e;
    },
    carouselMid(e) {
      this.midCk = e + 1;
    },
    moreProduct() {
      this.$router.push({
        path: `/Specific_m`,
      });
    },
    more2Product() {
      this.$router.push({
        path: `/medicinal` + (this.onbase == -1 ? "" : "?id=" + this.onbase),
      });
    },
    changeNewsTypeIndex(index) {
      if (this.newsTypeIndex == 2 && index == 1) {
        return;
      } else if (this.newsTypeIndex == 0 && index == -1) {
        return;
      }
      this.newsTypeIndex += index;
      if (index > 0) {
        this.container3[this.newsType].slideNext();
      } else {
        this.container3[this.newsType].slidePrev();
      }
    },
    getWXInfo() {
      const that = this;
      getSignature({ pageUrl: window.location.href }).then((res) => {
        const timestamp = res.data.timestamp;
        const nonceStr = res.data.noncestr;
        const signature = res.data.signature;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: "wxad8dd02ae97d307f", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
        });
        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          const obj = {
            title: that.companyItem.companyName, // 分享标题
            desc: that.companyItem.companyDesc, // 分享描述
            link:
              window.location.href +
              "?companyId=" +
              that.companyItem.companyId +
              "&companyArr=" +
              JSON.stringify(that.$store.getters.getCompanyTier), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: that.companyItem.companyImage, // 分享图标
          };
          wx.updateAppMessageShareData(obj);
          wx.updateTimelineShareData(obj);
        });
      });
    },
    jumpcityCompany(item) {
      this.$store.commit("setCompany", item);
      this.$router.push({
        path: `/projectCompany`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  width: 100%;
  height: 940px;
  padding-top: 175px;
  background-repeat: no-repeat;
  background-size: cover;

  .head_minBg {
    width: 100%;
    min-width: 1900px;
    height: 1115px;
    background: rgba(0, 0, 0, 0.65);
    backdrop-filter: blur(23px);
    position: absolute;
    top: 0;
    left: 0;
  }

  .headTv {
    width: 1451px;
    height: 848px;
    position: relative;
    margin: 0 auto;
    background: url("../../../static/v1.0/frame.png");
  }

  .hotImg {
    position: absolute;
    top: -11px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
  }

  .headBox {
    padding-top: 21px;
    overflow: hidden;
    width: 1400px;
    height: 800px;
    margin: 0 auto;
    display: flex;
  }

  .head_img {
    min-width: 1400px;
    height: 800px;
    margin: 0 auto;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.13) 0%,
      rgba(0, 0, 0, 0.83) 100%
    );

    .head_bg {
      .mask {
        width: 1400px;
        height: 400px;
        bottom: 0;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }
    }

    .head_item {
      position: absolute;
      bottom: 63px;
      left: 64px;

      .head_font {
        width: 1000px;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // word-break: break-all;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;
        // -webkit-line-clamp: 2;
        // height: 104px;
        font-weight: bold;
        font-size: 32px;
        color: #ffffff;
        line-height: 52px;
        letter-spacing: 1px;
        text-align: justify;
        font-style: normal;
      }

      .head_font1 {
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        line-height: 36px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        opacity: 0.9;
      }
    }
  }

  .cornerMarkBox {
    z-index: 8;
    position: absolute;
    width: 180px;
    display: flex;
    bottom: 64px;
    right: 66px;
    cursor: pointer;
    justify-content: flex-end;

    .cornerMark {
      width: 36px;
      height: 36px;
      color: #fff;
      text-align: center;
      font-weight: 500;
      font-size: 18px;
      line-height: 36px;
      margin-left: 10px;

      background: rgba(255, 255, 255, 0.2);
      border: 1px solid rgba(255, 255, 255, 0.45);
    }

    .ckcss {
      background: rgba(65, 47, 27, 0.8);
      font-weight: bold;
      font-size: 18px;
    }
  }

  ::v-deep .el-carousel__indicators--horizontal {
    display: none !important;
  }

  ::v-deep .el-carousel__container {
    height: 800px;
  }
}

.middle {
  // background: url("../../../static/v1.0/版块bg.png");
  position: relative;
  min-width: 1900px;
  height: 1848px;
  margin: 0 auto;
  background-size: cover;
  background-position-y: -550px;

  //   background-repeat: repeat-x;
  .imgbox {
    background: url("../../../static/v1.0/版块bg.png");
    width: 100%;
    height: 1800px;
    position: absolute;
    bottom: 23px;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -99;
    background-position-y: -100px;
  }

  .titile {
    height: 350px;
    margin: 0 auto;

    .oneI {
      position: absolute;
      top: 150px;
      left: 50%;
      transform: translateX(-50%);
    }

    .twoI {
      position: absolute;
      top: 176px;
      left: 50%;
      transform: translateX(-50%);
    }

    .img {
      width: 350px;
      height: 58px;
      position: absolute;
      top: 166px;
      left: 50%;
      transform: translateX(-50%);
    }

    .imgT {
      position: absolute;
      top: 243px;
      left: 50%;
      transform: translateX(-50%);
      font-weight: 400;
      font-size: 21px;
      color: #6d6d6d;
      line-height: 32px;
      letter-spacing: 25px;
      text-align: center;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .middle_new {
    display: flex;
    // width: 1900px;
    margin: 0 166px;
    justify-content: space-between;

    // background: #6d6d6d;
    // height: 1500px;
    .middle_Left {
      border: 4px solid #bdb1a3;
      min-width: 896px;
      height: 512.4px;
      position: relative;
      cursor: pointer;

      .mask {
        min-width: 896px;
        height: 250px;
        bottom: 0;
        // z-index: 8;
        position: absolute;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.83) 100%
        );
      }

      .cornerMarkBoxMid {
        right: 30px;
        bottom: 24px;
        z-index: 8;
        position: absolute;
        display: flex;

        .cornerMarkMid {
          cursor: pointer;
          width: 36px;
          height: 36px;
          font-weight: 500;
          line-height: 36px;
          text-align: center;
          font-size: 18px;
          background: rgba(255, 255, 255, 0.2);
          border: 1px solid #ffffff;
          opacity: 0.7;
          margin: 0 4px;
        }

        .ckcss {
          background: #412f1b;
          font-weight: bold;
        }
      }

      .middle_item {
        // width: 638px;
        .middle_font {
          font-family: kaiti;
          position: absolute;
          bottom: 24px;
          margin-left: 29px;
          width: 638px;
          overflow: hidden;
          font-weight: 900;
          font-size: 28px;
          color: #ffffff;
          line-height: 42px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .middle_Rigth {
      overflow: hidden;
      // height: 552px;
      height: 512.4px;

      .middle_Ritem:hover {
        color: #157b2f !important;
      }

      .middle_Ritem {
        cursor: pointer;
        border-bottom: 1px solid #f0f0f0;
        margin-left: 50px;
        margin-top: 50px;
      }

      .middle_Rfont {
        width: 580px;
        margin-bottom: 19px;
        overflow: hidden;
        font-family: kaiti;
        font-weight: 900;
        font-size: 28px;
        // color: #000000;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .minddle_time {
        // width: 92px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        font-size: 18px;
        // color: #090909;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }

      .minddle_danwie {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 20px;
        width: 359px;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 400;
        font-size: 18px;
        // color: #090909;
        line-height: 16px;
        text-align: left;
        font-style: normal;
      }
    }

    ::v-deep .el-carousel__indicators {
      display: none !important;
    }

    ::v-deep .el-carousel__container {
      height: 512.4px;
    }
  }

  .middle_three {
    display: flex;
    margin: 0 166px;
    // width: 1900px;
    margin-top: 144px;
    justify-content: space-between;

    .home_module_8_content_right {
      margin: 50px 0;

      .home_module_8_content_dom {
        display: flex;
        font-family: kaiti;
        font-size: 24px;
        align-items: center;
        justify-content: space-between;
      }

      .home_module_8_content_right_title {
        font-weight: bold;
        font-size: 35px;
        color: #000000;
        line-height: 42px;
      }

      .home_module_8_content_right_date {
        margin: 40px 0 100px 0;
      }

      .home_module_8_content_right_more {
        font-weight: bold;
        font-size: 44px;
        color: #000000;
        letter-spacing: 2px;
        margin-top: 30px;
        position: relative;
        width: fit-content;
        cursor: pointer;
      }

      .home_module_8_content_right_more::after {
        position: absolute;
        content: " ";
        bottom: -10px;
        left: 0;
        height: 2px;
        width: 100%;
        background: #2e2e2e;
      }

      .icon-left,
      .icon-right {
        border: 1px solid #1d1b19;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        font-size: 20px;
        cursor: pointer;
      }

      .icon-left {
        margin-right: 20px;
      }

      .nodata {
        color: #cbcbcb;
        border: 1px solid #cbcbcb;
      }
    }

    .home_module_8_module {
      display: flex;

      .home_module_8_module_item {
        flex: 1 1 33%;
        margin-right: 50px;
        cursor: pointer;

        .home_module_8_cover {
          width: 100%;
          overflow: hidden;

          img {
            width: 100%;
            transition: all 0.2s;
          }
        }

        .home_module_8_date {
          width: 50%;
          margin-top: 24px;
          transition: all 0.2s;
          color: #211f1f;

          div {
            width: 100%;
            font-size: 16px;
            line-height: 24px;
          }
        }

        .home_module_8_title {
          font-family: kaiti;
          width: 100%;
          margin-top: 20px;
          transition: all 0.2s;
          color: #211f1f;

          div {
            width: 100%;
            font-weight: bold;
            font-size: 28px;
            line-height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }

        .home_module_8_more1 {
          margin-top: 20px;

          .one {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0s infinite;
          }

          .two {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0.4s infinite;
          }

          .three {
            height: 18px;
            opacity: 0;
            /* 初始状态设置为不透明，因为我们想要从不透明开始淡出 */
            animation: fadeInFadeOut 1.5s ease-in-out 0.8s infinite;
          }

          @keyframes fadeInFadeOut {
            0%,
            100% {
              opacity: 0;
            }

            /* 动画开始和结束时都是透明的 */
            50% {
              opacity: 1;
            }

            /* 动画中间是不透明的 */
          }

          .home_module_8_more1::after {
            content: "";
            display: block;
            width: 28px;
            height: 12px;
            background-image: url("../../../static/v1.0/L_b.png");
            background-repeat: no-repeat;
            background-size: contain;
            margin-top: 34px;
          }
        }
      }

      .home_module_8_module_item:last-child {
        margin-right: 0;
      }

      .home_module_8_module_item:hover {
        img {
          transform: scale(1.1);
        }

        .home_module_8_date {
          color: #157b2f;
        }

        .home_module_8_title {
          color: #157b2f;
        }

        .home_module_8_more::after {
          width: 46px;
          background-image: url("../../../static/newImg2/more.png");
        }
      }
    }

    .middle_dom {
      width: 510px;

      .middle_img {
        width: 510px;
        height: 292px;
        background: skyblue;
      }

      .middle_time {
        margin-top: 32px;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        color: #211f1f;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }

      .middle_data {
        height: 84px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 900;
        font-size: 28px;
        color: #000000;
        line-height: 42px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
      }
    }

    ::v-deep .el-carousel__container {
      height: 800px;
      width: 1568px;
    }
  }
}

.specific {
  min-width: 1900px;
  margin: 0 auto;

  .specific_dom {
    margin: 0 160px;
    display: flex;
    margin-top: 60px;
    justify-content: center;
    text-align: center;

    .font_left {
      .title {
        font-family: 楷体;
        font-weight: 900;
        font-size: 50px;
        color: #000000;
        // line-height: 42px;
        letter-spacing: 2px;
        font-style: normal;
      }

      .font_ {
        width: 1192px;
        margin-top: 40px;
        margin-bottom: 111px;
        // height: 124px;
        font-weight: 400;
        font-size: 16px;
        color: #373737;
        line-height: 31px;
        text-align: left;
        font-style: normal;
      }

      .font_top {
        display: flex;
        justify-content: space-between;

        .number {
          font-weight: bold;
          font-size: 60px;
          color: #1e9409;
          line-height: 70px;
          text-align: left;
          font-style: normal;
        }

        .numberTpye {
          margin-top: 11px;
          font-weight: 400;
          font-size: 21px;
          color: #202020;
          line-height: 33px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
        }
      }

      .font_bottom {
        margin-top: 60px;
        width: 522px;
        display: flex;
        justify-content: space-between;

        .number {
          font-weight: bold;
          font-size: 60px;
          color: #1e9409;
          line-height: 70px;
          text-align: left;
          font-style: normal;
        }

        .numberTpye {
          font-weight: 400;
          font-size: 21px;
          color: #202020;
          line-height: 33px;
          letter-spacing: 1px;
          text-align: left;
          font-style: normal;
        }
      }
    }

    .allBranch {
      width: 1000px;
      overflow: hidden;
      position: absolute;
      right: 250px;
    }

    .right_img {
      position: relative;
      margin-left: 25px;
      width: 728px;
      height: 840px;
      background: url("../../../static/v1.0/绍兴map.jpg");

      .dot1 {
        display: flex;
        align-items: center;
        position: absolute;
        right: 18px;
        top: 34%;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 12px;
          background: #1b7c2b;
        }

        .home_map_right_introduction_flicker {
          position: absolute;
          width: 25px;
          height: 25px;
          left: 2%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: #136a2c;
          opacity: 0.2;
          animation: bigPointer 2s ease-out both;
          animation-iteration-count: infinite;
          transform-origin: 0 0;
        }

        .right_dot {
          width: 215px;
          height: 46px;
          text-align: center;
          line-height: 46px;
          background: #f8fff6;
          border-radius: 9px;
          border: 1px solid #45853b;
        }
      }

      .dot2 {
        display: flex;
        align-items: center;
        position: absolute;
        right: -35px;
        top: 54%;

        .dot {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 12px;
          background: #1b7c2b;
        }

        .home_map_right_introduction_flicker {
          position: absolute;
          width: 25px;
          height: 25px;
          left: 2%;
          top: 50%;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          background: #136a2c;
          opacity: 0.2;
          animation: bigPointer 2s ease-out both;
          animation-iteration-count: infinite;
          transform-origin: 0 0;
        }

        .right_dot1 {
          width: 215px;
          height: 46px;
          text-align: center;
          line-height: 46px;
          background: #f8fff6;
          border-radius: 9px;
          border: 1px solid #45853b;
        }
      }
    }
  }

  .specific_field {
    position: relative;
    margin-top: 85px;
    padding: 0 160px;

    // background: url("../../../static/v1.0/青山.png");
    // background-repeat: no-repeat;
    // background-size: cover;
    .field_dom {
      display: flex;
      flex-wrap: wrap;
      // width: 1900px;

      justify-content: space-between;

      .item_fieDom:hover {
        .item_Dom {
          transform: translateY(0);
          transition: all 0.5s;
        }

        .item_title {
          margin-bottom: 25px !important;
        }
      }

      .item_fieDom {
        background-size: cover !important;
        cursor: pointer;
        box-shadow: 0px 2px 11px 0 (53, 74, 50, 0.1);
        position: relative;
        height: 500px;
        width: 780px;
        overflow: hidden;
        border-radius: 10px;
        // flex: 0 48%;

        .mask {
          width: 780px;
          height: 300px;
          bottom: 0;
          position: absolute;
          background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0.83) 100%
          );
        }

        /* 每个项目占据一行宽度的1/3 */
        .item_Dom {
          position: absolute;
          height: 340px;
          bottom: 0;
          transform: translateY(140px);

          //   box-shadow: 0px 2px 31px 0px rgba(21, 123, 47, 0.18);
          .item_title {
            margin-top: 98px;
            margin-bottom: 55px;
            font-family: 楷体;
            font-weight: 900;
            font-size: 44px;
            color: #ffffff;
            line-height: 42px;
            letter-spacing: 1px;
            text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
            text-align: center;
          }

          .item_cent {
            height: 93px;
            overflow: hidden;
            text-overflow: ellipsis;
            // 只要超过宽度就换行，不论中文还是英文
            word-break: break-all;
            //最多展示两行
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            opacity: 0.8;
            font-weight: 400;
            margin: 0 80px;
            font-size: 16px;
            color: #ffffff;
            line-height: 31px;
            text-shadow: 0px 2px 31px rgba(21, 123, 47, 0.18);
            text-align: left;
            font-style: normal;
          }

          .jiaobioa {
            text-align: center;
          }
        }
      }

      .item_fieDom:nth-child(1) {
        // margin-right: 40px;
        margin-bottom: 40px;
      }
    }
  }
}

.base {
  position: relative;
  min-width: 1900px;
  margin: 0 auto;
  background: url("../../../static/v1.0/山.png");
  background-repeat: no-repeat;
  background-size: cover;

  .base_bul {
    margin: 0 160px;

    .titile {
      height: 350px;
      margin: 0 auto;

      .oneI {
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
      }

      .twoI {
        position: absolute;
        top: 176px;
        left: 50%;
        transform: translateX(-50%);
      }

      .img {
        width: 350px;
        height: 58px;
        position: absolute;
        top: 166px;
        left: 50%;
        transform: translateX(-50%);
      }

      .imgT {
        position: absolute;
        top: 243px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 21px;
        color: #6d6d6d;
        line-height: 32px;
        letter-spacing: 25px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    .base_dom {
      display: flex;
      justify-content: center;

      .base_all_dom:nth-child(1) {
        margin: 0;
      }

      .base_all_dom:nth-child(7) {
        margin: 0;
      }

      .base_all_dom {
        background: #f2f2f2;
        color: #4b4b4b;
        cursor: pointer;
        margin: 0 15px;
      }

      .base_all_dom:hover {
        background-color: rgba(11, 91, 32, 0.2);
      }

      .onbaseClass {
        background-color: #0b5b20 !important;
        border-radius: 6px;
        color: #ffffff !important;
      }

      .base_all {
        width: 200px;
        height: 82px;
        line-height: 82px;
        display: flex;
        justify-content: center;
        position: relative;
        align-items: center;
        border-radius: 6px;

        .base_img {
          position: absolute;
          right: 0;
        }

        .base_font {
          font-family: 楷体;
          font-weight: 400;
          font-size: 28px;

          line-height: 42px;
          letter-spacing: 2px;
          text-align: left;
        }
      }
    }

    .base_dom :nth-child(n) {
      // margin-left: 33px;
    }

    .base_dom :nth-child(1) {
      margin-left: 0px;
    }

    .base_child {
      width: 1550px;
      margin: 0 auto;

      .base_child_info_project {
        // margin: 70px 0;
        margin-top: 70px;
        padding-bottom: 150px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        .base_child_info_project_item:nth-child(2n-1) {
          margin-right: 50px;
          // background: red !important;
        }

        .base_child_info_project_item {
          cursor: pointer;

          margin-top: 30px;

          .base_child_info_project_item_img {
            position: relative;
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
            width: 750px;
            height: 362px;

            .base_child_title {
              height: 125px;
              line-height: 125px;
              width: 100%;
              background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0.03) 0%,
                rgba(0, 0, 0, 0.83) 100%
              );
              bottom: 0;
              font-family: 楷体;
              position: absolute;
              font-weight: 900;
              font-size: 36px;
              color: #ffffff;
              line-height: 42px;
              letter-spacing: 1px;
              // text-align: center;
            }

            overflow: hidden;

            img {
              display: block;
              border-radius: 5px;
              transition: all 1s;
            }
          }

          .base_child_info_project_item_tips {
            //  border-top-right-radius: 10px;
            // border-top-left-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 24px;
            max-width: 702px;
            background-color: #fff;
            box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);
            position: relative;

            .base_child_info_project_item_tips_left {
              font-size: 24px;
              font-weight: 400;
              color: #000;
              line-height: 40px;
            }

            .base_child_info_project_item_tips_right {
              font-size: 20px;
              color: #000;
              line-height: 27px;
              font-weight: 400;
              height: 55px;
              overflow: hidden;
              text-overflow: ellipsis;
              // 只要超过宽度就换行，不论中文还是英文
              word-break: break-all;
              //最多展示两行
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            }

            .base_child_info_project_item_tips_more {
              width: 500px;
              margin-top: 30px;
              font-size: 12px;
              font-weight: 600;
              color: #157b2f;
              // color: #595959;
              line-height: 20px;
              display: flex;
              align-items: center;

              img {
                vertical-align: middle;
                margin-left: 10px;
                transition: all 0.5s ease-out;
              }
            }

            .base_child_info_project_item_tips_line {
              width: 0;
              height: 2px;
              background-color: #1e9409;
              transition: all 1s;
              position: absolute;
              bottom: 0;
              left: 0;
            }
          }
        }

        .base_child_info_project_item:hover {
          .base_child_info_project_item_img img {
            transform: scale(1.1);
          }

          .base_child_info_project_item_tips_line {
            width: 100%;
          }
        }

        .base_child_info_project_item_only {
          display: flex;
          margin-top: 20px;
          cursor: pointer;

          .base_child_info_project_item_img {
            width: 750px;
            height: 362px;
            overflow: hidden;

            img {
              display: block;
            }
          }

          .base_child_info_project_item_tips {
            width: calc(100% - 590px - 48px);
            padding: 24px;
            background-color: #fff;
            box-shadow: 0px 2px 11px 0px rgba(53, 74, 50, 0.07);

            .base_child_info_project_item_tips_left {
              font-size: 20px;
              color: #000;
              line-height: 40px;
            }

            .base_child_info_project_item_tips_right {
              margin-top: 12px;
              font-size: 16px;
              font-weight: 400;
              color: #000;
              line-height: 27px;
              height: 85px;
              overflow: hidden;
            }

            .base_child_info_project_item_tips_more {
              width: 500px;
              margin-top: 30px;
              font-size: 12px;
              font-weight: 600;
              color: #157b2f;
              line-height: 20px;
              display: flex;
              align-items: center;

              img {
                vertical-align: middle;
                margin-left: 10px;
                transition: all 0.5s ease-out;
              }
            }
          }
        }
      }
    }
  }
}

.product {
  background: url("../../../static/v1.0/花.png");
  min-width: 1900px;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;

  .product_tit {
    margin: 0 160px;

    .titile {
      height: 350px;
      position: relative;
      margin: 0 auto;

      .oneI {
        position: absolute;
        top: 150px;
        left: 50%;
        transform: translateX(-50%);
      }

      .twoI {
        position: absolute;
        top: 176px;
        left: 50%;
        transform: translateX(-50%);
      }

      .img {
        width: 350px;
        height: 58px;
        position: absolute;
        top: 166px;
        left: 50%;
        transform: translateX(-50%);
      }

      .imgT {
        position: absolute;
        top: 250px;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 400;
        font-size: 21px;
        color: #6d6d6d;
        line-height: 32px;
        letter-spacing: 25px;
        text-align: center;
        font-style: normal;
        text-transform: uppercase;
      }
    }

    .product_font {
      // margin-top: 50px;
      padding-bottom: 50px;
      font-family: 楷体;
      font-weight: 400;
      font-size: 20px;
      color: #060606;
      line-height: 42px;
      text-align: center;
      font-style: normal;
    }

    .product_list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 80px;

      .product_dom:hover {
        .product_dom_font {
          transform: translateY(0);
          transition: all 1s;
        }
      }

      .product_dom {
        // filter: grayscale(80%);
        cursor: pointer;
        border-radius: 10px;
        position: relative;
        background: pink;
        margin-bottom: 40px;
        width: 512px;
        height: 384px;
        flex: 0 0 31.9%;
        /* 每个项目占据一行宽度的1/3 */
        overflow: hidden;

        .mask {
          width: 512px;
          height: 384px;
          position: absolute;
          background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.13) 0%,
            rgba(0, 0, 0, 0.83) 100%
          );
        }

        .product_dom_font {
          position: absolute;
          bottom: 40px;
          left: 38px;
          transform: translateY(230px);

          .product_dom_title {
            margin-bottom: 33px;
            font-weight: bold;
            font-size: 26px;
            color: #ffffff;
            line-height: 39px;
            // text-align: center;
            font-style: normal;
          }

          .product_dom_center {
            width: 400px;
            font-weight: bold;
            font-size: 14px;
            color: #ffffff;
            line-height: 29px;
            text-align: left;
            font-style: normal;
          }
        }
      }

      .product_dom:nth-child(2) {
        margin: 0 32px;
        background: #157b2f;
      }

      .product_dom:nth-child(5) {
        margin: 0 32px;
        background: red;
      }
    }
  }
}

.more:hover {
  text-decoration-line: underline;
  transition: all 0.1s linear 0s;
  transform: translate(-50%, -10px);
}
.more2:hover {
  text-decoration-line: underline;
  transition: all 0.1s linear 0s;
  transform: translate(0, -10px) !important;
}

.more {
  font-family: kaiti;
  // margin-top: 85px;
  cursor: pointer;
  font-weight: 400;
  position: absolute;
  left: 50%;
  font-size: 36px;
  transform: translateX(-50%);
  color: #060606;
  width: 200px;
  // line-height: 10px;
  text-align: center;
  font-style: normal;
}
.font {
  height: 101px;
  border-top: 1px solid #e6e6e6;
  width: 1568px;
  margin: 0 auto;
  background: #fff;
  overflow: hidden;
  display: flex;

  .font_dom {
    z-index: 10;
    // width: 200px;
    height: 100px;
    background: #fff;
    display: flex;
    align-items: center;
    // margin-right: 25px;
  }

  .font_for {
    display: flex;
  }
}

.backimg {
  background: url("../../../static/v1.0/青山.png");
  width: 100%;
  height: 1400px;
  position: absolute;
  bottom: -97%;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -99;
  background-position-y: -100px;
}
</style>
